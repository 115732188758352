
import {
  defineComponent, ref, computed
} from 'vue'
import ServicesNumbersBuyWrapper from '@/components/pages/services/numbers/buy/ServicesNumbersBuyWrapper.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import type { FlagCountry } from '@/definitions/shared/types'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmTooltip,
    ServicesNumbersBuyWrapper,
    TmFormLine,
    TmAlert,
    TmButton,
  },
  setup() {
    const { isLoadingMode } = useModes()
    const phoneCountryVariant: FlagCountry[] = [
      {
        name: 'United States (Toll-free numbers)',
        id: 'us',
      }, {
        name: 'Denmark',
        id: 'dk',
      }, {
        name: 'France',
        id: 'fr',
      }, {
        name: 'Estonia',
        id: 'ee',
      }, {
        name: 'Germany',
        id: 'de',
      },
    ]
    const phoneCountry = ref<FlagCountry>(phoneCountryVariant[0])
    const isNonUs = computed(() => phoneCountry.value.id !== 'us')

    const numberType = ref('Toll-free number')

    const phoneVariant = [
      '+18332057316 (Two-way SMS & Calls)',
      '+28332057316 (Two-way SMS & Calls)',
      '+38332057316 (Two-way SMS & Calls)',
      '+48332057316 (Two-way SMS & Calls)',
      '+58332057316 (Two-way SMS & Calls)',
    ]

    const phone = ref('+18332057316 (Two-way SMS & Calls)')

    const areaCodeVariant = [
      '907 – Alaska (AK)',
      '205, 251, 256, 334, 938 Alabama (AL)',
      '302 - Delaware (DE)',
    ]

    const areaCode = ref('907 – Alaska (AK)')

    const accountVariant = [
      {
        label: 'John Doe',
        email: 'john.doe@textmagic.com',
        avatarColor: 'red',
      },
      {
        label: 'Anita Doe',
        email: 'anita.doe@textmagic.com',
        url: 'https://randomuser.me/api/portraits/women/94.jpg',
      },
      {
        label: 'Olga Doe',
        email: 'olga.doe@textmagic.com',
        avatarColor: 'blue',
      },
    ]

    const account = ref(accountVariant[0])

    return {
      isLoadingMode,
      phoneCountryVariant,
      phoneCountry,
      numberType,
      phoneVariant,
      phone,
      isNonUs,
      areaCodeVariant,
      areaCode,
      accountVariant,
      account,
    }
  },
})
